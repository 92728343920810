import { default as classNames, default as cx } from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { usePortal } from 'hooks/usePortal'
import { ReactNode } from 'react'
import { createPortal } from 'react-dom'
import { twMerge } from 'tailwind-merge'

export type ModalProps = {
  isOpen: boolean
  onClose: () => void
  children?: ReactNode | ReactNode[]
  className?: string
  containerClassName?: string
  full?: boolean
  hideOverflow?: boolean
  target?: string
  onOpen?: () => void
}

export function Modal(props: ModalProps) {
  const { full, hideOverflow, isOpen, onClose, children, onOpen } = props
  const target = usePortal(props.target ? `portal__${props.target}` : 'main__modal')
  const overflowClasses = hideOverflow && 'md:overflow-hidden md:h-full'

  const onAnimationComplete = () => {
    onOpen && onOpen()
  }

  if (!target) {
    return null
  }
  return createPortal(
    <motion.div
      style={{
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        pointerEvents: isOpen ? 'auto' : 'none',
        overflow: 'auto',
      }}
      className={twMerge('z-50', props.className)}
    >
      {isOpen && (
        <div className="fixed inset-0 cursor-pointer transition-opacity" aria-hidden="true" onClick={onClose}>
          <div className="dark:bg-dark-800/80 absolute inset-0 bg-gray-500/80 backdrop-blur-sm"></div>
        </div>
      )}
      <div className={cx('flex sm:my-8', full && 'flex-1 md:px-8', props.containerClassName)}>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              className={classNames('flex w-full justify-center', full ? 'flex-1' : 'items-start', overflowClasses)}
              initial={{ opacity: 0, translateY: 16, scale: 0.95 }}
              animate={{ opacity: 1, translateY: 0, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              onAnimationComplete={onAnimationComplete}
            >
              <div
                className={classNames(
                  'flex transform cursor-pointer flex-col text-left transition-all',
                  full && 'flex-1',
                  overflowClasses,
                )}
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
                onClick={props.onClose}
              >
                <div
                  className={classNames(
                    'mb-8 flex cursor-default flex-col',
                    full && 'flex-1 overflow-hidden',
                    overflowClasses,
                  )}
                  onClick={e => e.stopPropagation()}
                >
                  {children}
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.div>,
    target,
  )
}
