import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { cn } from '@/lib/utils'
import { useQuery } from '@apollo/client'
import { Badge } from 'components/Badge'
import { ConnectOwnerButton } from 'components/Buttons/ConnectOwnerButton'
import { DescriptionDetails } from 'components/DescriptionDetails'
import { DescriptionTerm } from 'components/DescriptionTerm'
import { Icon } from 'components/Icon'
import { Loader } from 'components/Loader'
import { Text } from 'components/Text'
import { QueryDriverFull } from 'internal/graphql/query/driver'
import { QueryProvider } from 'internal/graphql/query/provider'
import { QueryVehicleFull } from 'internal/graphql/query/vehicle'
import Link from 'next/link'
import React from 'react'
import { HiOutlineOfficeBuilding, HiOutlineUser } from 'react-icons/hi'
import { RiTaxiLine } from 'react-icons/ri'
import { DocumentLink } from 'types/Document'
import { formatName } from 'util/driver'

type OwnerLinkProps = {
  baseUrl: string
  context?: string
  className?: string
  owner: DocumentLink
  linkable?: boolean
  documentId?: string
  decorations?: boolean
  customDetails?: React.ReactNode
}

export function OwnerLink(props: OwnerLinkProps) {
  const { baseUrl, className, context, decorations = true, owner } = props
  if (!owner?.type) {
    return props.linkable && props.documentId ? <ConnectOwnerButton documentId={props.documentId} /> : null
  }

  let label = ''
  let customNumber

  if (context === 'provider' && owner.type === 'provider') {
    return <Text>{props.owner.provider.name}</Text>
  }

  switch (owner.type) {
    case 'driver':
      if (owner.driver) {
        label = formatName(owner.driver)
        customNumber = owner.driver.driverNumber
      } else {
        label = owner.driverId
      }
      break

    case 'vehicle':
      label = owner.vehicle?.vin
      break

    case 'provider':
      label = owner?.provider?.name
      break
  }

  return (
    <TooltipProvider>
      <Tooltip delayDuration={0}>
        <TooltipTrigger asChild>
          <Link
            href={`${baseUrl}/${owner.type}s/${owner[`${owner.type}Id`] || owner[owner.type]?.id}`}
            className={cn([
              'inline-flex items-center justify-start gap-1 text-left font-medium capitalize text-gray-900 hover:underline dark:text-gray-100',
              className,
            ])}
          >
            <OwnerIcon type={owner.type} />
            {decorations && customNumber && (
              <Badge size="xs" padding="xs" weight={600} className="mr-1">
                {customNumber}
              </Badge>
            )}
            {label}
          </Link>
        </TooltipTrigger>
        <TooltipContent side="right" className="border bg-card p-3 text-card-foreground shadow">
          <OwnerDetails owner={owner} customDetails={props.customDetails} />
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

/*

*/

function OwnerIcon({ type }: { type: string }) {
  switch (type) {
    case 'driver':
      return <Icon icon={HiOutlineUser} size="sm" color="black" />
    case 'vehicle':
      return <Icon icon={RiTaxiLine} size="sm" color="black" />
    case 'provider':
      return <Icon icon={HiOutlineOfficeBuilding} size="sm" color="black" />
  }

  return null
}

function OwnerDetails({ owner, customDetails }: { owner: DocumentLink; customDetails?: React.ReactNode }) {
  let Query = owner.type === 'driver' ? QueryDriverFull : owner.type === 'vehicle' ? QueryVehicleFull : QueryProvider
  const ownerId = owner[`${owner.type}Id`] || owner[owner.type]?.id
  const { data } = useQuery(Query, { variables: { id: ownerId } })
  const ownerData = data?.[owner.type]

  if (!data) return <Loader />

  let entityDetails: React.ReactNode
  switch (owner.type) {
    case 'driver':
      entityDetails = (
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-2">
            <DescriptionTerm>Type</DescriptionTerm>
            <DescriptionDetails className="mt-2">
              <Text size="sm">{ownerData?.documentGroup?.name}</Text>
            </DescriptionDetails>
          </div>
          <div className="sm:col-span-1">
            <DescriptionTerm>Market</DescriptionTerm>
            <DescriptionDetails className="mt-2">
              <Text size="sm">{ownerData?.provider?.market.name}</Text>
            </DescriptionDetails>
          </div>
          <div className="sm:col-span-1">
            <DescriptionTerm>Provider Type</DescriptionTerm>
            <DescriptionDetails className="mt-2">
              <Text size="sm">{ownerData?.provider?.documentGroup?.name}</Text>
            </DescriptionDetails>
          </div>
          <div className="sm:col-span-1">
            <DescriptionTerm>DOB</DescriptionTerm>
            <DescriptionDetails className="mt-2">
              <Text size="sm">{ownerData?.dob}</Text>
            </DescriptionDetails>
          </div>
          <div className="sm:col-span-1">
            <DescriptionTerm>SSN</DescriptionTerm>
            <DescriptionDetails className="mt-2">
              <Text size="sm">xxx-xx-{ownerData?.ssn?.slice(-4)}</Text>
            </DescriptionDetails>
          </div>
        </div>
      )
      break
    case 'vehicle':
      entityDetails = (
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-2">
            <DescriptionTerm>Type</DescriptionTerm>
            <DescriptionDetails className="mt-2">
              <Text size="sm">{ownerData?.documentGroup?.name}</Text>
            </DescriptionDetails>
          </div>
          <div className="sm:col-span-1">
            <DescriptionTerm>Market</DescriptionTerm>
            <DescriptionDetails className="mt-2">
              <Text size="sm">{ownerData?.provider?.market.name}</Text>
            </DescriptionDetails>
          </div>
          <div className="sm:col-span-1">
            <DescriptionTerm>Provider Type</DescriptionTerm>
            <DescriptionDetails className="mt-2">
              <Text size="sm">{ownerData?.provider?.documentGroup?.name}</Text>
            </DescriptionDetails>
          </div>
          <div className="sm:col-span-2">
            <DescriptionTerm>VIN</DescriptionTerm>
            <DescriptionDetails className="mt-2">
              <Text size="sm">{ownerData?.vin}</Text>
            </DescriptionDetails>
          </div>
          <div className="sm:col-span-2">
            <DescriptionTerm>Plate</DescriptionTerm>
            <DescriptionDetails className="mt-2">
              <Text size="sm">{ownerData?.plate}</Text>
            </DescriptionDetails>
          </div>
        </div>
      )
      break
    case 'provider':
      entityDetails = (
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-2">
            <DescriptionTerm>Type</DescriptionTerm>
            <DescriptionDetails className="mt-2">
              <Text size="sm">{ownerData?.documentGroup?.name}</Text>
            </DescriptionDetails>
          </div>
          <div className="sm:col-span-1">
            <DescriptionTerm>Market</DescriptionTerm>
            <DescriptionDetails className="mt-2">
              <Text size="sm">{ownerData?.market?.name}</Text>
            </DescriptionDetails>
          </div>
        </div>
      )
      break
  }

  return (
    <div className="flex flex-col gap-3">
      {entityDetails}
      {customDetails}
    </div>
  )
}
