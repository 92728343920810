import { Box } from 'components/Box'
import { Button } from 'components/Button'
import { VehiclesList } from 'components/network/tickets/VehiclesList'
import { useFlags } from 'flagsmith/react'
import { shouldShowVINs } from 'helpers/document'
import { useDocumentVINs } from 'hooks/useDocumentVINs'
import { useLazyProviderTraits } from 'hooks/useLazyProviderTraits'
import { useRoles } from 'hooks/useRoles'
import { updateProviderTraits } from 'lib/api/providerTraits'
import { noop, xor } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Document } from 'types'

type Props = {
  document?: Document
}

export function DocumentModalVehiclesList({ document }: Props) {
  const { load, refetch, traits } = useLazyProviderTraits({
    id: document?.owner?.providerId,
  })
  const flags = useFlags(['coi'])
  const isCoiEnabled = flags?.coi?.enabled
  const [selectedVehicles, setSelectedVehicles] = useState<string[]>([])

  const { load: loadDocumentVINs, vins } = useDocumentVINs(document?.id)
  const selectedVins = useMemo(() => traits?.scheduledAutoVins || [], [traits?.scheduledAutoVins])

  const { isRiskManager } = useRoles()

  async function updateTraits() {
    updateProviderTraits({
      providerId: document.owner.providerId,
      traits: {
        scheduledAutoVins: selectedVehicles,
      },
    })
      .then(async r => {
        if (!r.ok) {
          toast.error('Failed to update vehicles. Please try again.')

          return
        }
      })
      .then(() => {
        toast.success('Vehicles updated successfully')
      })
      .finally(() => {
        refetch()
      })
  }

  useEffect(() => {
    if (document?.owner?.providerId) {
      load()
    }
  }, [document, load])

  useEffect(() => {
    setSelectedVehicles(selectedVins)
  }, [selectedVins, traits])

  useEffect(() => {
    if (!isCoiEnabled) return

    if (!shouldShowVINs(document?.extractedData)) return
    loadDocumentVINs()
  }, [loadDocumentVINs, document?.extractedData, isCoiEnabled])

  const hasChanges = xor(selectedVehicles, selectedVins).length > 0

  return (
    <Box className="relative flex h-full flex-col overflow-hidden pt-3">
      <VehiclesList
        documentVins={vins}
        isResolved
        onCancel={noop}
        providerId={document?.owner?.providerId}
        selectedVehicles={selectedVehicles}
        setSelectedVehicles={setSelectedVehicles}
        isReadOnly={isRiskManager}
      />
      {hasChanges && (
        <Button variant="primary" className=" absolute right-4 top-10 shadow" onClick={() => updateTraits()}>
          Save Changes
        </Button>
      )}
    </Box>
  )
}
